var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"linkifier_row"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depths[1],"can_modify"),lookupProperty(depths[1],"can_drag"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":61}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":82}}})) != null ? stack1 : "")
    + "\" data-linkifier-id=\""
    + alias4(alias3(alias2(depth0, "id", {"start":{"line":2,"column":105},"end":{"line":2,"column":107}} ), depth0))
    + "\">\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depths[1],"can_modify"),lookupProperty(depths[1],"can_drag"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":45}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"linkifier_pattern\">"
    + alias4(alias3(alias2(depth0, "pattern", {"start":{"line":10,"column":42},"end":{"line":10,"column":49}} ), depth0))
    + "</span>\n    </td>\n    <td>\n        <span class=\"linkifier_url_template\">"
    + alias4(alias3(alias2(depth0, "url_template", {"start":{"line":13,"column":47},"end":{"line":13,"column":59}} ), depth0))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " movable-row";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"move-handle\">\n                <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n                <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n            </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"no-select actions\">\n        "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"aria-label":lookupProperty(helpers,"t").call(alias1,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":21},"end":{"line":22,"column":31}}}),"data-tippy-content":lookupProperty(helpers,"t").call(alias1,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":29},"end":{"line":21,"column":39}}}),"custom_classes":"tippy-zulip-delayed-tooltip edit","intent":"neutral","icon":"edit"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"aria-label":lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":21},"end":{"line":29,"column":33}}}),"data-tippy-content":lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":29},"end":{"line":28,"column":41}}}),"custom_classes":"tippy-zulip-delayed-tooltip delete","intent":"danger","icon":"trash"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"linkifier"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":34,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});