var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hide\" id=\"user-group-creation\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group creation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":42}}}))
    + "\">\n    <form id=\"user_group_creation_form\">\n        <div class=\"two-pane-settings-creation-simplebar-container\" data-simplebar data-simplebar-tab-index=\"-1\">\n            <div class=\"alert user_group_create_info\"></div>\n            <div id=\"user_group_creating_indicator\"></div>\n            <div class=\"user-group-creation-body\">\n                <div class=\"configure_user_group_settings user_group_creation\">\n                    <section id=\"user-group-name-container\">\n                        <label for=\"create_user_group_name\" class=\"settings-field-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":52}}}))
    + "\n                        </label>\n                        <input type=\"text\" name=\"user_group_name\" id=\"create_user_group_name\" class=\"settings_text_input\"\n                          placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":39},"end":{"line":14,"column":63}}}))
    + "\" value=\"\" autocomplete=\"off\" maxlength=\""
    + alias2(container.lambda(container.strict(depth0, "max_user_group_name_length", {"start":{"line":14,"column":107},"end":{"line":14,"column":133}} ), depth0))
    + "\"/>\n                        <div id=\"user_group_name_error\" class=\"user_group_creation_error\"></div>\n                        <a id=\"deactivated_group_rename\"></a>\n                    </section>\n                    <section id=\"user-group-description-container\">\n                        <label for=\"create_user_group_description\" class=\"settings-field-label\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":59}}}))
    + "\n                        </label>\n                        <input type=\"text\" name=\"user_group_description\" id=\"create_user_group_description\" class=\"settings_text_input\"\n                          placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":39},"end":{"line":23,"column":70}}}))
    + "\" value=\"\" autocomplete=\"off\" />\n                    </section>\n                    <section id=\"user-group-permission-container\">\n                        <div class=\"group-permissions settings-subsection-parent\" id=\"new_group_permission_settings\">\n                            <div class=\"subsection-header\">\n                                <h3 class=\"user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Group permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":80},"end":{"line":28,"column":106}}}))
    + "\n                                </h3>\n                            </div>\n\n                            "
    + ((stack1 = container.invokePartial(require("./group_permissions.hbs"),undefined,{"name":"group_permissions","hash":{"group_setting_labels":lookupProperty(lookupProperty(depth0,"all_group_setting_labels"),"group"),"prefix":"id_new_group_"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                    </section>\n                </div>\n                <div class=\"user_group_members_container user_group_creation\">\n                    <section id=\"choose_member_section\">\n                        <label for=\"people_to_add_in_group\">\n                            <h4 class=\"user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Choose members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":76},"end":{"line":39,"column":99}}}))
    + "</h4>\n                        </label>\n                        <div id=\"user_group_membership_error\" class=\"user_group_creation_error\"></div>\n                        <div class=\"controls\" id=\"people_to_add_in_group\"></div>\n                    </section>\n                </div>\n            </div>\n        </div>\n        <div class=\"settings-sticky-footer\">\n            <div class=\"settings-sticky-footer-left\">\n                "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"id":"user_group_go_to_configure_settings","intent":"brand","attention":"quiet","custom_classes":"hide","label":lookupProperty(helpers,"t").call(alias1,"Back to settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":50,"column":46}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"settings-sticky-footer-right\">\n                "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"intent":"neutral","attention":"quiet","custom_classes":"create_user_group_cancel inline-block","label":lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":24},"end":{"line":59,"column":36}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"type":"submit","intent":"brand","attention":"quiet","custom_classes":"finalize_create_user_group hide","label":lookupProperty(helpers,"t").call(alias1,"Create",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":24},"end":{"line":65,"column":36}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"intent":"brand","attention":"quiet","custom_classes":"inline-block","id":"user_group_go_to_members","label":lookupProperty(helpers,"t").call(alias1,"Continue to add members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":72,"column":24},"end":{"line":72,"column":53}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});