var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"alert-word-item\" data-word='"
    + alias3(alias2(alias1(depth0, "word", {"start":{"line":3,"column":41},"end":{"line":3,"column":45}} ), depth0))
    + "'>\n    <td>\n        <div class=\"alert_word_listing\">\n            <span class=\"value\">"
    + alias3(alias2(alias1(depth0, "word", {"start":{"line":6,"column":34},"end":{"line":6,"column":38}} ), depth0))
    + "</span>\n        </div>\n    </td>\n    <td>\n        "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"data-tippy-content":lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":29},"end":{"line":14,"column":41}}}),"custom_classes":"delete remove-alert-word tippy-zulip-delayed-tooltip","intent":"danger","icon":"trash"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"alert_word"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":18,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});