var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button class=\"create_user_group_button two-pane-settings-plus-button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"zulip_plan_is_not_limited"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":15,"column":101},"end":{"line":15,"column":132}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":95},"end":{"line":15,"column":149}}})) != null ? stack1 : "")
    + ">\n                            <i class=\"create_button_plus_sign zulip-icon zulip-icon-user-group-plus\" aria-hidden=\"true\"></i>\n                        </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"settings-empty-option-text\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You do not have permission to create user groups.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":28},"end":{"line":51,"column":86}}}))
    + "\n                        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"groups_overlay\" class=\"two-pane-settings-overlay overlay\" data-overlay=\"group_subscriptions\">\n    <div class=\"flex overlay-content\">\n        <div class=\"two-pane-settings-container overlay-container\">\n            <div class=\"two-pane-settings-header\">\n                <div class=\"fa fa-chevron-left\"></div>\n                <span class=\"user-groups-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User groups",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":48},"end":{"line":6,"column":68}}}))
    + "</span>\n                <div class=\"exit\">\n                    <span class=\"exit-sign\">&times;</span>\n                </div>\n            </div>\n            <div class=\"left\">\n                <div class=\"list-toggler-container\">\n                    <div id=\"add_new_user_group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_create_user_groups"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":18,"column":31}}})) != null ? stack1 : "")
    + "                        <div class=\"float-clear\"></div>\n                    </div>\n                </div>\n                <div class=\"input-append group_name_search_section\" id=\"group_filter\">\n                    <input type=\"text\" name=\"group_name\" id=\"search_group_name\" class=\"filter_text_input\" autocomplete=\"off\"\n                      placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":50}}}))
    + "\" value=\"\"/>\n                    <button type=\"button\" class=\"clear_search_button\" id=\"clear_search_group_name\">\n                        <i class=\"zulip-icon zulip-icon-close\" aria-hidden=\"true\"></i>\n                    </button>\n                    <span>\n                        <label class=\"checkbox\" id=\"user-group-edit-filter-options\">\n                            "
    + ((stack1 = container.invokePartial(require("../dropdown_widget.hbs"),undefined,{"name":"../dropdown_widget","hash":{"widget_name":"user_group_visibility_settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </label>\n                    </span>\n                </div>\n                <div class=\"no-groups-to-show\">\n                </div>\n                <div class=\"user-groups-list\" data-simplebar data-simplebar-tab-index=\"-1\">\n                </div>\n            </div>\n            <div class=\"right\">\n                <div class=\"display-type\">\n                    <div id=\"user_group_settings_title\" class=\"user-group-info-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":86},"end":{"line":41,"column":114}}}))
    + "</div>\n                    <i class=\"fa fa-ban deactivated-user-icon deactivated-user-group-icon-right\"></i>\n                </div>\n                <div class=\"nothing-selected\">\n                    <div class=\"group-info-banner\"></div>\n                    <div class=\"create-group-button-container\">\n                        "
    + ((stack1 = container.invokePartial(require("../settings/upgrade_tip_widget.hbs"),depth0,{"name":"../settings/upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        <button type=\"button\" class=\"create_user_group_button animated-purple-button\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"can_create_user_groups"),lookupProperty(depth0,"zulip_plan_is_not_limited"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":48,"column":112},"end":{"line":48,"column":166}}}),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":102},"end":{"line":48,"column":187}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create user group",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":188},"end":{"line":48,"column":214}}}))
    + "</button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_create_user_groups"),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":24},"end":{"line":53,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n                <div id=\"user_group_settings\" class=\"settings\" data-simplebar data-simplebar-tab-index=\"-1\" data-simplebar-auto-hide=\"false\">\n                </div>\n                "
    + ((stack1 = container.invokePartial(require("./user_group_creation_form.hbs"),depth0,{"name":"user_group_creation_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});