var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"input-group\">\n                    <label class=\"settings-field-label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_can_change_email"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":55},"end":{"line":10,"column":110}}})) != null ? stack1 : "")
    + "\" for=\"change_email_button\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":138},"end":{"line":10,"column":152}}}))
    + "</label>\n                    <div class=\"change-email\">\n                        <div id=\"email_field_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_can_change_email"),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":76},"end":{"line":12,"column":144}}})) != null ? stack1 : "")
    + "\">\n                            <input type=\"email\" value=\""
    + alias2(container.lambda(container.strict(lookupProperty(depth0,"current_user"), "delivery_email", {"start":{"line":13,"column":57},"end":{"line":13,"column":84}} ), depth0))
    + "\" class=\"settings_text_input\" disabled=\"disabled\" />\n                        </div>\n                        "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"data-tippy-content":lookupProperty(helpers,"t").call(alias1,"Change your email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":45},"end":{"line":23,"column":68}}}),"aria-hidden":"true","aria-label":lookupProperty(helpers,"t").call(alias1,"Change your email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":37},"end":{"line":21,"column":60}}}),"hidden":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"user_can_change_email"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":20,"column":33},"end":{"line":20,"column":60}}}),"custom_classes":"tippy-zulip-delayed-tooltip","intent":"brand","icon":"edit","id":"change_email_button"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                    <div id=\"email-change-status\"></div>\n                </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "cursor-text";
},"4":function(container,depth0,helpers,partials,data) {
    return "disabled_setting_tooltip";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"input-group\">\n                    <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":37,"column":31}}})) != null ? stack1 : "")
    + "                    </p>\n                    "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"intent":"brand","attention":"quiet","label":lookupProperty(helpers,"t").call(alias1,"Add email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":28},"end":{"line":41,"column":43}}}),"id":"demo_organization_add_email_button"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            Add your email to <z-link-invite-users-help>invite other users</z-link-invite-users-help>\n                            or <z-link-convert-demo-organization-help>convert to a permanent Zulip organization</z-link-convert-demo-organization-help>.\n                            \n                            \n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/invite-new-users\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/demo-organizations#convert-a-demo-organization-to-a-permanent-organization\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),undefined,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p for=\"two_factor_auth\" class=\"inline-block title\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Two factor authentication",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":51,"column":50}}}))
    + ": "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"page_params"),"two_fa_enabled_user"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":51,"column":52},"end":{"line":51,"column":140}}})) != null ? stack1 : "")
    + "\n                <a target=\"_blank\" rel=\"noopener noreferrer\" id=\"two_factor_auth\" href=\"/account/two_factor/\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Set up two factor authentication",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":117},"end":{"line":52,"column":158}}}))
    + "\">["
    + alias2(lookupProperty(helpers,"t").call(alias1,"Setup",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":161},"end":{"line":52,"column":175}}}))
    + "]</a>\n            </p>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Enabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":92},"end":{"line":51,"column":108}}}));
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Disabled",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":116},"end":{"line":51,"column":133}}}));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>\n                    <label class=\"settings-field-label\" for=\"change_password\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":78},"end":{"line":59,"column":95}}}))
    + "</label>\n                    <div class=\"input-group\">\n                        "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"id":"change_password","intent":"brand","attention":"quiet","label":lookupProperty(helpers,"t").call(alias1,"Change your password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":32},"end":{"line":62,"column":58}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"custom_classes":"deactivate_realm_button inline-block","intent":"danger","attention":"quiet","label":lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Deactivate organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":28},"end":{"line":84,"column":57}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "                    An API key can be used to programmatically access a Zulip account.\n                    Anyone with access to your API key has the ability to read your messages, send\n                    messages on your behalf, and otherwise impersonate you on Zulip, so you should\n                    guard your API key as carefully as you guard your password. <br />\n                    We recommend creating bots and using the bots' accounts and API keys to access\n                    the Zulip API, unless the task requires access to your account.\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"account-settings\" class=\"settings-section\" data-name=\"account-and-privacy\">\n    <div class=\"alert\" id=\"dev-account-settings-status\"></div>\n    <div class=\"account-settings-form\">\n        <div id=\"user_details_section\">\n            <h3 class=\"inline-block account-settings-heading\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":78}}}))
    + "</h3>\n            <div class=\"alert-notification account-alert-notification\" id=\"account-settings-status\"></div>\n            <form class=\"grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":46,"column":23}}})) != null ? stack1 : "")
    + "            </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"page_params"),"two_fa_enabled"),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":54,"column":19}}})) != null ? stack1 : "")
    + "\n            <form class=\"password-change-form grid\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_can_change_password"),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":69,"column":23}}})) != null ? stack1 : "")
    + "            </form>\n\n            <div class=\"input-group\">\n                <div id=\"deactivate_account_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_only_organization_owner"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":75},"end":{"line":73,"column":145}}})) != null ? stack1 : "")
    + "\">\n                    "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"disabled":lookupProperty(depth0,"user_is_only_organization_owner"),"id":"user_deactivate_account_button","intent":"danger","attention":"quiet","label":lookupProperty(helpers,"t").call(alias1,"Deactivate account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":28},"end":{"line":75,"column":52}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"owner_is_only_user_in_organization"),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":16},"end":{"line":89,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        "
    + ((stack1 = container.invokePartial(require("./privacy_settings.hbs"),depth0,{"name":"privacy_settings","hash":{"hide_read_receipts_tooltip":lookupProperty(lookupProperty(depth0,"realm"),"realm_enable_read_receipts"),"read_receipts_help_icon_tooltip_text":lookupProperty(depth0,"send_read_receipts_tooltip"),"prefix":"user_","for_realm_settings":false},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div id=\"api_key_button_box\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":16},"end":{"line":96,"column":32}}}))
    + "</h3>\n\n            <div class=\"input-group\">\n                <p class=\"api-key-note\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":20},"end":{"line":107,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n                <div id=\"api_key_button_container\" class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":71},"end":{"line":109,"column":136}}})) != null ? stack1 : "")
    + "\">\n                    "
    + ((stack1 = container.invokePartial(require("../components/action_button.hbs"),undefined,{"name":"../components/action_button","hash":{"disabled":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":115,"column":31},"end":{"line":115,"column":55}}}),"id":"api_key_button","intent":"brand","attention":"quiet","label":lookupProperty(helpers,"t").call(alias1,"Manage your API key",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":28},"end":{"line":111,"column":53}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n        <!-- Render /settings/api_key_modal.hbs after #api_key_button is clicked\n        to avoid password being inserted by password manager too aggressively. -->\n    </div>\n</div>\n";
},"7_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-invite-users-help"],"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":177}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link-convert-demo-organization-help"],"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":36,"column":248}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});