var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"privacy_settings settings-subsection-parent\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return " id=\"privacy_settings_box\"\n  ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h3 class=\"inline-block\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Privacy settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":57}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":false,"section_name":"privacy-setting"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <h3 class=\"inline-block\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Privacy",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":48}}}))
    + "</h3>\n        "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),undefined,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":9,"column":92},"end":{"line":9,"column":116}}}),"section_name":"privacy-setting"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"help_link":"/help/export-your-organization#export-your-organization","label":lookupProperty(lookupProperty(depth0,"settings_label"),"allow_private_data_export"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"allow_private_data_export"),"setting_name":"allow_private_data_export"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Who can access user's email address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":54,"column":55}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Who can access your email address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":56,"column":53}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "disabled_setting_tooltip";
},"17":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":2,"column":9}}})) != null ? stack1 : "")
    + ">\n    <div class=\"subsection-header inline-block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_private_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_private_typing_notifications"),"setting_name":"send_private_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_stream_typing_notifications"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_stream_typing_notifications"),"setting_name":"send_stream_typing_notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"hide_tooltip":lookupProperty(depth0,"hide_read_receipts_tooltip"),"help_icon_tooltip_text":lookupProperty(depth0,"read_receipts_help_icon_tooltip_text"),"help_link":"/help/read-receipts","prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"send_read_receipts"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"send_read_receipts"),"setting_name":"send_read_receipts"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),undefined,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"help_link":"/help/status-and-availability","label_parens_text":lookupProperty(lookupProperty(depth0,"settings_label"),"presence_enabled_parens_text"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"presence_enabled"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"presence_enabled"),"setting_name":"presence_enabled"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":49,"column":15}}})) != null ? stack1 : "")
    + "\n    <div class=\"input-group\">\n        <label for=\""
    + alias4(alias3(alias2(depth0, "prefix", {"start":{"line":52,"column":22},"end":{"line":52,"column":28}} ), depth0))
    + "email_address_visibility\" class=\"settings-field-label\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":57,"column":19}}})) != null ? stack1 : "")
    + "            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),undefined,{"name":"../help_link_widget","hash":{"link":"/help/configure-email-visibility"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n        <div id=\"user_email_address_dropdown_container\"\n          class=\"inline-block "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":30},"end":{"line":61,"column":95}}})) != null ? stack1 : "")
    + "\">\n            <select name=\"email_address_visibility\"\n              class=\"email_address_visibility prop-element settings_select bootstrap-focus-style\"\n              data-setting-widget-type=\"number\"\n              id=\""
    + alias4(alias3(alias2(depth0, "prefix", {"start":{"line":65,"column":20},"end":{"line":65,"column":26}} ), depth0))
    + "email_address_visibility\"\n              "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":14},"end":{"line":66,"column":74}}})) != null ? stack1 : "")
    + ">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),undefined,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"email_address_visibility_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});