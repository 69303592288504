var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"uploaded_file_row\" data-attachment-name=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":2,"column":54},"end":{"line":2,"column":58}} ), depth0))
    + "\" data-attachment-id=\""
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":2,"column":84},"end":{"line":2,"column":86}} ), depth0))
    + "\">\n    <td>\n        <a type=\"submit\" class=\"tippy-zulip-delayed-tooltip\" href=\"/user_uploads/"
    + alias3(alias2(alias1(depth0, "path_id", {"start":{"line":4,"column":83},"end":{"line":4,"column":90}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" data-tippy-content=\""
    + alias3(lookupProperty(helpers,"t").call(alias4,"View file",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":156},"end":{"line":4,"column":174}}}))
    + "\">\n            "
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":5,"column":15},"end":{"line":5,"column":19}} ), depth0))
    + "\n        </a>\n    </td>\n    <td>"
    + alias3(alias2(alias1(depth0, "create_time_str", {"start":{"line":8,"column":11},"end":{"line":8,"column":26}} ), depth0))
    + "</td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"messages"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td class=\"upload-size\" >"
    + alias3(alias2(alias1(depth0, "size_str", {"start":{"line":20,"column":32},"end":{"line":20,"column":40}} ), depth0))
    + "</td>\n    <td class=\"actions\">\n        <span class=\"edit-attachment-buttons\">\n            <a type=\"submit\" href=\"/user_uploads/"
    + alias3(alias2(alias1(depth0, "path_id", {"start":{"line":23,"column":51},"end":{"line":23,"column":58}} ), depth0))
    + "\" class=\"hidden-attachment-download\" download></a>\n            "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"data-tippy-content":lookupProperty(helpers,"t").call(alias4,"Download",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":33},"end":{"line":28,"column":47}}}),"custom_classes":"tippy-zulip-delayed-tooltip download-attachment","intent":"info","icon":"download"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n        <span class=\"edit-attachment-buttons\">\n            "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"data-tippy-content":lookupProperty(helpers,"t").call(alias4,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":33},"end":{"line":36,"column":45}}}),"intent":"danger","custom_classes":"tippy-zulip-delayed-tooltip delete remove-attachment","icon":"trash"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"attachment-messages\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"messages"),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":16,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <a class=\"ind-message\" href=\"/#narrow/id/"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":13,"column":60},"end":{"line":13,"column":67}} ), depth0))
    + "\">\n                    #"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":14,"column":24},"end":{"line":14,"column":31}} ), depth0))
    + "\n                </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"attachment"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":41,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});