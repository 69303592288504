var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"playground_row\" data-playground-id=\""
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":2,"column":49},"end":{"line":2,"column":51}} ), depth0))
    + "\">\n    <td>\n        <span class=\"playground_pygments_language\">"
    + alias3(alias2(alias1(depth0, "pygments_language", {"start":{"line":4,"column":53},"end":{"line":4,"column":70}} ), depth0))
    + "</span>\n    </td>\n    <td>\n        <span class=\"playground_name\">"
    + alias3(alias2(alias1(depth0, "playground_name", {"start":{"line":7,"column":40},"end":{"line":7,"column":55}} ), depth0))
    + "</span>\n    </td>\n    <td>\n        <span class=\"playground_url_template\">"
    + alias3(alias2(alias1(depth0, "url_template", {"start":{"line":10,"column":48},"end":{"line":10,"column":60}} ), depth0))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"no-select actions\">\n        "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"aria-label":lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":21},"end":{"line":19,"column":33}}}),"data-tippy-content":lookupProperty(helpers,"t").call(alias1,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":29},"end":{"line":18,"column":41}}}),"custom_classes":"delete-code-playground delete","intent":"danger","icon":"trash"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"playground"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":24,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});