var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("./message_avatar.hbs"),depth0,{"name":"message_avatar","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"sender_info_hover sender_name\" role=\"button\" tabindex=\"0\">\n            <span class=\"view_user_card_tooltip sender_name_text\" data-is-bot=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "sender_is_bot", {"start":{"line":7,"column":81},"end":{"line":7,"column":94}} ), depth0))
    + "\">\n                "
    + ((stack1 = container.invokePartial(require("./user_full_name.hbs"),undefined,{"name":"user_full_name","hash":{"should_add_guest_user_indicator":lookupProperty(depth0,"should_add_guest_indicator_for_sender"),"name":lookupProperty(lookupProperty(depth0,"msg"),"sender_full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </span>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"status_message"),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":12,"column":23}}})) != null ? stack1 : "")
    + "        </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"sender_is_bot"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_message"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"message_edit_notices_alongside_sender"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./status_emoji.hbs"),lookupProperty(lookupProperty(depth0,"msg"),"status_emoji_info"),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"zulip-icon zulip-icon-bot\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":69}}}))
    + "\"></i>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"rendered_markdown status-message\">"
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(alias1,lookupProperty(depth0,"status_message"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":18,"column":59},"end":{"line":18,"column":95}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"message_edit_notices_for_status_message"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = container.invokePartial(require("./edited_notice.hbs"),depth0,{"name":"edited_notice","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = container.invokePartial(require("./edited_notice.hbs"),depth0,{"name":"edited_notice","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"starred-marker "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"msg"),"starred"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":27},"end":{"line":30,"column":60}}})) != null ? stack1 : "")
    + "\">\n    <i class=\"starred-marker-icon zulip-icon zulip-icon-starred-marker\"></i>\n</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "starred";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "href=\""
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"msg"), "url", {"start":{"line":35,"column":42},"end":{"line":35,"column":49}} ), depth0))
    + "\"";
},"18":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"copy-paste-text\">&nbsp;</span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span data-tooltip-template-id=\"slow-send-spinner-tooltip-template\" class=\"fa fa-circle-o-notch slow-send-spinner"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"msg"),"show_slow_send_spinner"),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":117},"end":{"line":43,"column":174}}})) != null ? stack1 : "")
    + "\"></span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"msg"),"failed_request"),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":52,"column":15}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = container.invokePartial(require("./message_controls_failed_msg.hbs"),undefined,{"name":"message_controls_failed_msg","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = container.invokePartial(require("./message_controls.hbs"),depth0,{"name":"message_controls","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = container.invokePartial(require("./message_controls.hbs"),depth0,{"name":"message_controls","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_hidden"),{"name":"unless","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":59,"column":4},"end":{"line":71,"column":15}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"message_content rendered_markdown\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"use_match_properties"),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":65,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"msg"),"match_content"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":62,"column":51}}}))
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"msg"),"content"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":64,"column":12},"end":{"line":64,"column":45}}}))
    + "\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"message_content rendered_markdown\">\n        "
    + ((stack1 = container.invokePartial(require("./message_hidden_dialog.hbs"),undefined,{"name":"message_hidden_dialog","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./edited_notice.hbs"),depth0,{"name":"edited_notice","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("./message_reactions.hbs"),depth0,{"name":"message_reactions","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"include_sender"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":7}}})) != null ? stack1 : "")
    + "<span class=\"message_sender\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"include_sender"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "</span>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"locally_echoed"),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":0},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "\n<a "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"locally_echoed"),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":3},"end":{"line":35,"column":64}}})) != null ? stack1 : "")
    + " class=\"message-time\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"include_sender"),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "    "
    + container.escapeExpression(container.lambda(container.strict(depth0, "timestr", {"start":{"line":39,"column":6},"end":{"line":39,"column":13}} ), depth0))
    + "\n</a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"failed_request"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":42,"column":11},"end":{"line":42,"column":35}}}),lookupProperty(lookupProperty(depth0,"msg"),"locally_echoed"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":42,"column":55}}}),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":0},"end":{"line":44,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"message_controls no-select\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"msg"),"locally_echoed"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":47,"column":4},"end":{"line":55,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"status_message"),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":0},"end":{"line":72,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"message_edit_notices_in_left_col"),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":0},"end":{"line":76,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"message_length_controller\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_hidden"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":80,"column":11},"end":{"line":80,"column":26}}}),lookupProperty(lookupProperty(depth0,"msg"),"message_reactions"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":80,"column":6},"end":{"line":80,"column":49}}}),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":0},"end":{"line":82,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});