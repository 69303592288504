var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8_patch_hash=53ad8d748fa2ca3a7a0502c46f2ed1236eef8162efec84c06849dc83ad9e28e9/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"invite_row\" data-invite-id=\""
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":2,"column":41},"end":{"line":2,"column":43}} ), depth0))
    + "\" data-is-multiuse=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"is_multiuse"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":65},"end":{"line":2,"column":116}}})) != null ? stack1 : "")
    + "\">\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_multiuse"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <td>\n        <span class=\"invited_at\">"
    + alias3(alias2(alias1(depth0, "invited_absolute_time", {"start":{"line":22,"column":35},"end":{"line":22,"column":56}} ), depth0))
    + "</span>\n    </td>\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"expiry_date_absolute_time"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":29,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n    <td>\n        <span>"
    + alias3(alias2(alias1(depth0, "invited_as_text", {"start":{"line":32,"column":16},"end":{"line":32,"column":31}} ), depth0))
    + "</span>\n    </td>\n    <td class=\"actions\">\n        "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"disabled":lookupProperty(depth0,"disable_buttons"),"data-tippy-content":lookupProperty(helpers,"t").call(alias4,"Revoke",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":29},"end":{"line":39,"column":41}}}),"custom_classes":"revoke tippy-zulip-delayed-tooltip","intent":"danger","icon":"trash"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias4,lookupProperty(depth0,"is_multiuse"),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":56,"column":19}}})) != null ? stack1 : "")
    + "    </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "false";
},"4":function(container,depth0,helpers,partials,data) {
    return "true";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"email\">\n            <a href=\""
    + alias1(container.lambda(container.strict(depth0, "link_url", {"start":{"line":6,"column":23},"end":{"line":6,"column":31}} ), depth0))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\">\n                "
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invite link",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":35}}}))
    + "\n            </a>\n        </span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"email\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "email", {"start":{"line":11,"column":30},"end":{"line":11,"column":35}} ), depth0))
    + "</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"referred_by panel_user_list\">\n            "
    + ((stack1 = container.invokePartial(require("../user_display_only_pill.hbs"),undefined,{"name":"../user_display_only_pill","hash":{"is_active":true,"user_id":lookupProperty(depth0,"invited_by_user_id"),"display_value":lookupProperty(depth0,"referrer_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </span>\n    </td>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"expires_at\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "expiry_date_absolute_time", {"start":{"line":26,"column":35},"end":{"line":26,"column":60}} ), depth0))
    + "</span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"expires_at\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Never expires",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":33},"end":{"line":28,"column":54}}}))
    + "</span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"disabled":true,"custom_classes":"check hide","intent":"success","icon":"check"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("../components/icon_button.hbs"),undefined,{"name":"../components/icon_button","hash":{"disabled":lookupProperty(depth0,"disable_buttons"),"data-tippy-content":lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Resend",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":29},"end":{"line":53,"column":41}}}),"custom_classes":"resend tippy-zulip-delayed-tooltip","intent":"neutral","icon":"send-dm"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"invite"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":59,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});